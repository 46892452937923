import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdsList } from "../../slice/ListSlice";
import { FaTags } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

const PromotedAndNewAdsSlide = (props) => {
  const { category } = props;
  const dispatch = useDispatch();
  const catAdsList = useSelector((store) => store.ads.catAdsList);
  const catAds = catAdsList?.data || [];

  const slideRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    slideRef.current.slickNext();
  };
  const prevSlide = () => {
    slideRef.current.slickPrev();
  };
  useEffect(() => {
    if (category) {
      dispatch(
        getAdsList({
          skip: 0,
          limit: 10,
          category: category,
        })
      );
    }
  }, [category]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };
  return (
    <div className="w-full flex justify-center py-4 bg-slate-50">
      <div className="slider container w-12/12 relative  ">
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-blue-500 font-bold text-2xl">
              <span className="text-[#234777]">Related</span>
              <span className="text-[#01C6DA]"> Ads</span>
            </h1>
            {/* <Link to="/featured-ads">
              <button className="flex text-lg font-semibold text-[#234777] hover:text-[#01C6DA]">
                See All <FiChevronRight className="relative top-1.5 text-lg" />
              </button>
            </Link> */}
          </div>
          <div className="py-4">
            <Slider className="" ref={slideRef} {...settings}>
              {catAds.items?.map((item, index) => (
                <Link to={`/ads-detail/${item.slug}`} key={index}>
                  {/* <div key={index} className="px-2 slide object-cover max-h-48">
                    <img src={item.images[0]?.image_path} alt={item.title} />
                  </div> */}
                  <div className="group px-4 h-[400px]">
                    <div className="w-full h-full flex flex-col overflow-hidden bg-white border border-slate-200 shadow-lg rounded-lg">
                      <div className="h-48">
                        {item.images ? (
                          <img
                            className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                            src={item.images[0]?.image_path}
                            alt={item.title}
                            onError={(e) => {
                              e.target.src = "/img/no-image-available.jpg";
                            }}
                          />
                        ) : (
                          <img src={"/img/no-image-available.jpg"} alt="Default" />
                        )}
                      </div>
                      <div className="p-4 flex flex-col flex-1">
                        <div className="flex items-center gap-1 text-[#999999] pb-2">
                          <div>
                            <FaTags />
                          </div>
                          <div>{item.category.name}</div>
                        </div>
                        <div>
                          <hr className="border-1 text-[#999999]" />
                        </div>
                        <div className="flex-1 flex-col">
                          <div className="text-lg text-[#232D3B] py-1">
                            <div className="font-bold group-hover:text-yellow-500">
                              {truncateString(item.title, 30)}
                              {/* {items.title} */}
                            </div>
                          </div>
                          <div className="flex items-start gap-1 pb-2 text-[#999999]">
                            <div className="pt-1">
                              <MdLocationOn />
                            </div>
                            <div className="">{item.location.city}</div>
                          </div>
                        </div>
                        <div>
                          <hr className="border-1 text-[#999999]" />
                        </div>
                        <div className="flex justify-between align-middle font-bold text-[#232D3B] text-lg py-2">
                          <div className="w-7/12">{item.currency.symbol} {item.price}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
        <button
          className="prev-button absolute top-1/2  text-3xl bg-transparent  transform -translate-y-1/2 -translate-x-3 border border-slate-200 rounded-2xl"
          onClick={prevSlide}
        >
          <FiChevronLeft />
        </button>
        <button
          className="next-button absolute  right-0  top-1/2  text-3xl transform -translate-y-1/2 translate-x-3  bg-transparent border border-slate-200 rounded-2xl"
          onClick={nextSlide}
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default PromotedAndNewAdsSlide;

import { useState, useEffect, useRef } from "react";
import {
  updateBusinessStore,
  createBusinessStore,
  getBusinessStore,
} from "../slice/StoreSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getUserDetails } from "../slice/AuthSlice";

function UpgradeToStore() {
  const dispatch = useDispatch();
  const userDetails = useSelector((store) => store.auth?.userDetail?.data);

  const businessStore = useSelector((store) => store.store.businessStore);

  const [formData, setFormData] = useState({
    id: "",
    business_company_registration: "",
    business_owner: "",
    business_name: "",
    business_company_name: "",
    business_company_no: "",
    personal_email: "",
    business_email: "",
    business_website: "",
    business_logo: "",
    business_address: "",
    personal_phone_number: "",
    business_phone_number: "",
    customer_id: "",
    status: "",
  });
  const [preview, setPreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const init = async () => {
    if (userDetails) {
      dispatch(
        getBusinessStore({
          customer_id: userDetails.customer_id,
        })
      );
    } else {
      await dispatch(getUserDetails()).unwrap().then((r) => {
        dispatch(
          getBusinessStore({
            customer_id: r.data.customer_id,
          })
        );
      })
    }
    
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (businessStore.data) {
      setFormData(businessStore.data);
    }
  }, [businessStore]);

  const fileSelectedHandler = (event) => {
    console.log(event)
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
      setFormData({...formData, business_logo: reader.result.split(",")[1]});
    };
    reader.readAsDataURL(file);
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const onSubmit = async () => {
    try {
      if (formData.id) {
        await dispatch(
          updateBusinessStore({
            business_id: formData.store_id,
            payload: formData,
          })
        ).unwrap();
      } else {
        await dispatch(createBusinessStore(formData)).unwrap();
      }
      toast.success("Data has been saved");
      init();
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="w-full p-6 overflow-auto">
      <h2 className="text-2xl text-center text-[#234777] font-bold mb-2">
        Upgrade To Business Store
      </h2>
      <p className="text-gray-600 mb-4">
        You can upgrade your account to business store and have your own store
        page.
      </p>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">
          Status
        </label>
        <select
          name="status"
          value={formData.status}
          onChange={(e) => {
            setFormData({ ...formData, status: e.target.value });
          }}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="active">Active</option>
          <option value="inactive">Disable</option>
        </select>
      </div>

      <div className=" grid grid-cols-2 gap-4 mb-4">
        <div className="mb-4">
          <label className="text-[#234777] font-semibold" htmlFor="">Logo</label>
          <div
            className="overflow-hidden cursor-pointer h-full"
            onClick={handleImageClick}
          >
            {preview ? (
              <img
                src={preview}
                alt="User Avatar"
                className="w-48 h-48 object-contain cursor-pointer"
              />
            ) : (
              <div className="rounded-md h-full flex justify-center items-center border-[1px] border-slate-400 border-dashed">
                <span>Upload Logo Here</span>
              </div>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            style={{ display: "none" }}
            onChange={fileSelectedHandler}
          />
        </div>
        <label className="block mb-2 text-[#234777] font-semibold">
          Business name
          <input
            type="text"
            name="business_name"
            required
            placeholder="Business name"
            className="form-input mt-1 p-2 py-2 w-full border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_name}
            onChange={(e) => {
              setFormData({
                ...formData,
                business_name: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Company Registration
          <input
            type="text"
            name="business_company_registration"
            required
            placeholder="Company Registration"
            className="form-input mt-1 p-2 py-2 w-full border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_company_registration}
            onChange={(e) => {
              setFormData({
                ...formData,
                business_company_registration: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Name of Director
          <input
            type="text"
            name="business_name_of_director"
            required
            placeholder="Name of Director"
            className="form-input mt-1 p-2 py-2 w-full border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_owner}
            onChange={(e) => {
              setFormData({
                ...formData,
                business_owner: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Company Name
          <input
            type="text"
            name="companyName"
            required
            placeholder="Company Name"
            className="form-input mt-1 p-2 py-2 w-full border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_company_name}
            onChange={(e) => {
              setFormData({
                ...formData,
                business_company_name: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Company No
          <input
            type="text"
            name="companyNo"
            required
            placeholder="Company No"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_company_no}
            onChange={(e) => {
              setFormData({ ...formData, business_company_no: e.target.value });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Personal Email
          <input
            type="email"
            name="personal_email"
            required
            placeholder="Personal Email"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.personal_email}
            onChange={(e) => {
              setFormData({ ...formData, personal_email: e.target.value });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Business Email
          <input
            type="email"
            name="business_email"
            required
            placeholder="Business Email"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_email}
            onChange={(e) => {
              setFormData({ ...formData, business_email: e.target.value });
            }}
          />
        </label>

        <label className="block mb-2 text-[#234777] font-semibold">
          Personal No
          <input
            type="text"
            name="personal_phone_number"
            required
            placeholder="Personal No"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.personal_phone_number}
            onChange={(e) => {
              setFormData({
                ...formData,
                personal_phone_number: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Business No
          <input
            type="text"
            name="business_phone_number"
            required
            placeholder="Business No"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_phone_number}
            onChange={(e) => {
              setFormData({
                ...formData,
                business_phone_number: e.target.value,
              });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Website
          <input
            type="text"
            name="business_website"
            required
            placeholder="Website"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_website}
            onChange={(e) => {
              setFormData({ ...formData, business_website: e.target.value });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Address
          <textarea
            type="text"
            name="business_address"
            required
            placeholder="Address"
            className="form-input w-full mt-1 p-2 py-2 border rounded-lg border-slate-300 outline-blue-500"
            value={formData.business_address}
            onChange={(e) => {
              setFormData({ ...formData, business_address: e.target.value });
            }}
          />
        </label>
      </div>

      <div className="w-full flex justify-center">
        <button
          onClick={onSubmit}
          className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600"
        >
          SAVE CHANGES
        </button>
      </div>
    </div>
  );
}

export default UpgradeToStore;

import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : { 
    getAffiliateList: (position,skip,limit) => {
      return Api.get(
        `v1/affiliate?skip=${skip}&limit=${limit}`
      );
    },
    getAffiliateListTop: () => {
      return Api.get(
        `v1/affiliate?position=top`
      );
    },
  }
import React, { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Navbar from "../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createAdsList } from "../../slice/ListSlice";
import { CategoryTreeChild, getCurrency } from "../../slice/CategorySlice";
import { createBanner } from "../../slice/BannerSlice";
import Footer from "../Footer";

function PostBanner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageBase64, setImageBase64] = useState("");
  // const { slug, id } = useParams();
  const initialFormState = {
    title: "",
    url_link: "",
    img: null,
    size_img: "",
    author_id: 0,
    user_id: parseInt(localStorage.getItem("customer_id")),
  };

  const [formState, setFormState] = useState(initialFormState);
  const [activeInput, setActiveInput] = useState(null);

  // const categoryAdsData = useSelector((store) => store.categories.catTreeChild);
  // const SubCatPost = categoryAdsData?.data || [];

  // const catMasterData = useSelector((store) => store.categories.currency);
  // const CatMaster = catMasterData?.data || [];

  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleInputBlur = () => {
    setActiveInput(null);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
        setFormState({
          ...formState,
          [name]: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log(localStorage.getItem("token"));
  }, []);
  // useEffect(() => {
  //   dispatch(CategoryTreeChild({ id }));
  // }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formState);
    if (formState.title === "" || formState.url_link === "") {
      alert("Please fill in all required fields.");
      return;
    }
    if (formState.img === "") {
      alert("Please select image");
      return;
    }
    try {
      await dispatch(createBanner({ formData: formState })).unwrap;
      navigate("/subscription");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <div
        className="w-full bg-cover bg-center h-full"
        style={{ backgroundImage: `url(/img/form-bg-1.jpg)` }}
      >
        <div className="pt-32 lg:pt-20 w-full flex flex-col justify-center items-center gap-5 bg-black bg-opacity-50">
          <form
            className="w-11/12 md:w-6/12 bg-white p-4"
            onSubmit={handleSubmit}
          >
            <div className="full border-2 border-slate-300 shadow-lg p-4">
              <div className="text-[#234777] text-4xl font-bold py-4">
                Post Banner
              </div>
              <div>
                <hr></hr>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">Title</div>
                <div>
                  <input
                    type="text"
                    name="title"
                    value={formState.title}
                    placeholder="Type Your Banner Title Here"
                    onFocus={() => handleInputFocus("title")}
                    onBlur={handleInputBlur}
                    onChange={handleChange}
                    className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                      activeInput === "title"
                        ? "border-b-2 border-blue-500"
                        : ""
                    }`}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">URL</div>
                <div>
                  <input
                    type="text"
                    name="url_link"
                    value={formState.url_link}
                    placeholder="Type Your Banner Link Here"
                    onFocus={() => handleInputFocus("url_link")}
                    onBlur={handleInputBlur}
                    onChange={handleChange}
                    className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                      activeInput === "url_link"
                        ? "border-b-2 border-blue-500"
                        : ""
                    }`}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">
                  Add Image
                </div>
                <div>
                  <input
                    type="file"
                    name="img"
                    onChange={handleChange}
                    className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                    placeholder="Type Your Product Title Here"
                  />
                </div>
                {imageBase64 && (
                  <div>
                    <img
                      src={imageBase64}
                      alt="Selected"
                      className="w-full h-auto mb-4"
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex gap-10 py-5 text-center">
                <div className="flex-1 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button
                    type="button"
                    className="flex w-full gap-2 justify-center items-center"
                    onClick={() => navigate(-1)}
                  >
                    <BsFillArrowLeftCircleFill />
                    Back
                  </button>
                </div>
                <div className="flex-1 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button type="submit">SUBMIT</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PostBanner;

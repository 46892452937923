import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMyBanner, getBannerList } from "../slice/BannerSlice";

import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import BannerDetailForAuthor from "./BannerDetailForAuthor";
import BannerDetail from "./BannerDetail";

const MyBanner = ({ customerId }) => {
  const dispatch = useDispatch();
  const bannerListData = useSelector((store) => store.banner.myBannerList);
  const bannerListDataPub = useSelector((store) => store.banner.bannerList);
  const banner = useSelector((store) => store.banner);
  const loader = useRef(null);
  //banner
  const [dataBanner, setDataBanner] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [page, setPage] = useState(1);
  const limit = 20;
  const sort_type = "desc";
  const [isOpen, setIsOpen] = useState(false);
  const init = () => {
    const skip = (page - 1) * limit;
    if (customerId) {
      dispatch(getBannerList({ skip, limit, sort_type, user_id: customerId }));
    } else {
      dispatch(getMyBanner({ skip, limit, sort_type }));
    }
  };
  const onRefresh = () => {
    setDataBanner([]);
    setPage(1);
    init();
  };
  // useEffect(() => {
  //   onRefresh();
  // }, []);
  useEffect(() => {
    init();
  }, [page, dispatch]);
  useEffect(() => {
    if (customerId) {
      // if (bannerListDataPub?.data?.items?.length) {
      const dataMap = bannerListDataPub?.data?.items.map((item) => {
        return {
          ...item,
        };
      });
      setDataBanner([...dataBanner, ...(dataMap ?? [])]);
      // }
    } else {
      if (bannerListData?.data?.items?.length) {
        const dataMap = bannerListData?.data?.items.map((item) => {
          return {
            ...item,
          };
        });
        setDataBanner([...dataBanner, ...(dataMap ?? [])]);
      }
    }
  }, [bannerListData, bannerListDataPub]);
  return (
    <>
      <div className="text-3xl font-bold py-4">Banner Adverts</div>
      <div className="h-[865px] overflow-y-auto">
        <div className="flex-grow flex flex-col justify-between">
          <InfiniteScroll
            dataLength={dataBanner.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={true}
            loader={
              <div ref={loader} style={{ height: "200px" }}>
                {banner.loading && (
                  <div className="flex gap-5">
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full sm:w-80 h-48 bg-gray-100 rounded">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1 }}>
              <Masonry gutter="20px">
                {dataBanner.map((item, i) => (
                  <button
                    onClick={() => {
                      setSelectedData(item);
                      setIsOpen(true);
                    }}
                    key={i}
                    className="w-full block overflow-hidden"
                  >
                    <img
                      className="w-full rounded-md h-auto max-h-[500px] object-cover"
                      src={item.img}
                      alt={item.title}
                    />
                  </button>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        </div>
      </div>
      {customerId ? (
        <BannerDetail
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={selectedData}
        />
      ) : (
        <BannerDetailForAuthor
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={selectedData}
          onRefresh={() => {
            onRefresh();
          }}
        />
      )}
    </>
  );
};

export default MyBanner;

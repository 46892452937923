import React, { useEffect, useState, useRef } from "react";
import { BiSolidUser, BiUserCircle } from "react-icons/bi";
import { AiFillHeart, AiFillHome } from "react-icons/ai";
import {
  MdCancel,
  MdLocationPin,
  MdLogout,
  MdOutlineNewLabel,
} from "react-icons/md";
import { AiOutlineMenuFold } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import {
  FaCar,
  FaFileInvoice,
  FaHouseUser,
  FaIndustry,
  FaPencilAlt,
  FaShoppingBag,
  FaBuysellads,
  FaFighterJet,
  FaCreditCard,
  FaBalanceScale,
  FaCalendar,
  FaBuilding,
  FaBus,
  FaLaptop,
  FaTags,
  FaBook,
  FaMapPin,
} from "react-icons/fa";
import { PiFlagBanner } from "react-icons/pi";
import { FaUserAlt } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { BiDesktop } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import "../input.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoMdList } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { CategoryTree, getCategoriesList } from "../slice/CategorySlice";
import { setLatitude, setLongitude } from "../slice/GeoLocationSlice";
import { logOut } from "../slice/AuthSlice";
import { getGlobalSearch } from "../slice/ListSlice";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const dispatch = useDispatch();
  const { searchValue } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categoryAdsData = useSelector((store) => store.categories.categoryList);
  const categoryAds = categoryAdsData?.data || [];

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchCategorySelected, setSearchCategorySelected] = useState({
    text: "All categories",
    value: "",
  });

  const { logIn } = useSelector((store) => store.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [isOpenCategorySearch, setIsOpenCategorySearch] = useState(false);

  const dropdownRef = useRef(null);
  const dropdownRefSearch = useRef(null);

  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };
  const ShowModal = () => {
    setShowModal(!showModal);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefSearch.current &&
        !dropdownRefSearch.current.contains(event.target)
      ) {
        setTimeout(() => {
          setIsOpenCategorySearch(false);
        }, 500);
      }
    };
    if (isOpenCategorySearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenCategorySearch]);

  useEffect(() => {
    dispatch(getCategoriesList({ is_parent: "yes" }));
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    // dispatch(getGlobalSearch({ searchData: { keyword: searchKeyword } }));
    navigate(`/search-results/${searchKeyword}`);
  };

  useEffect(() => {
    if (location.pathname.includes("search-results")) {
      setSearchKeyword(searchValue);
    }
    return () => {};
  }, [location]);

  const getIcon = (iconname) => {
    switch (iconname) {
      case "fa-industry":
        return <FaIndustry />;
      case "fa-credit-card":
        return <FaCreditCard />;
      case "fa-fighter-jet":
        return <FaFighterJet />;
      case "fa-shopping-bag":
        return <FaShoppingBag />;
      case "fa-balance-scale":
        return <FaBalanceScale />;
      case "fa-calendar":
        return <FaCalendar />;
      case "fa-building":
        return <FaBuilding />;
      case "fa-bus":
        return <FaBus />;
      case "fa-laptop":
        return <FaLaptop />;
      case "fa-tags":
        return <FaTags />;
      case "fa-book":
        return <FaBook />;
      case "banner":
        return <PiFlagBanner />;
      default:
        return <FaBuysellads />;
    }
  };

  //geo loc
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const latitude = useSelector((store) => store.location?.latitude);
  const longitude = useSelector((store) => store.location?.longitude);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(setLatitude(position.coords.latitude));
          dispatch(setLongitude(position.coords.longitude));
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [dispatch]);

  useEffect(() => {
    if (latitude && longitude) {
      const fetchLocationData = async () => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`;

        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const result = await response.json();
          const address = result.address;
          setCity(
            address.city || address.town || address.village || "Unknown city"
          );
          setCountry(address.country || "Unknown country");
        } catch (err) {
          console.log("Unable to fetch location data.");
        }
      };

      fetchLocationData();
    }
  }, [latitude, longitude]);
  return (
    <div className="w-full fixed z-20  bg-white shadow shadow-slate-300">
      <div className="flex justify-between h-20 w-11/12 m-auto items-center">
        <div className="flex gap-4 items-center">
          <button
            className="bg-[#F5F5F5] h-10 w-10 flex justify-center items-center rounded-full text=[#555555] hover:bg-[#234784] hover:text-white"
            onClick={toggleSideBar}
          >
            <IoMdList className="h-5 w-5 rounded-full" />
          </button>
          <Link to="/">
            <img src="/img/wwaLogo.png" alt="logo" className="w-48 sm:w-56" />
          </Link>
        </div>
        <div className="relative hidden sm:block md:hidden lg:block">
          <form onSubmit={handleSearch}>
            <div className="flex">
              <div className="relative flex-shrink-0 inline-flex">
                <div className="flex">
                  <Link
                    id="dropdown-button"
                    data-dropdown-toggle="dropdown"
                    className="flex-shrink-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    // type="button"
                    // onClick={() => setIsOpenCategorySearch(!isOpenCategorySearch)}
                    to={"/category-menu"}
                  >
                    <IoMdList className="h-5 w-5 rounded-full" />
                    {/* {searchCategorySelected.text}
                    <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                    </svg> */}
                  </Link>
                </div>
                {/* {isOpenCategorySearch && (
                  <div ref={dropdownRefSearch} className="absolute bg-white divide-y top-11 divide-gray-100 rounded-lg shadow w-44">
                    <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdown-button">
                      <li>
                        <button 
                          onClick={() => {
                            setSearchCategorySelected({
                              text: 'All categories',
                              value: '',
                            })
                            setIsOpenCategorySearch(false)
                          }} 
                          type="button" 
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100"
                          >
                            All categories
                        </button>
                      </li>
                      {categoryAds.items?.map((item, index) => (
                        <li key={index}>
                          <button 
                            onClick={() => {
                              setSearchCategorySelected({
                                text: item.name,
                                value: item.slug,
                              })
                              setIsOpenCategorySearch(false)
                            }} 
                            type="button" 
                            className="inline-flex w-full px-4 py-2 hover:bg-gray-100"
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </div>
              <div className="relative w-full">
                <input
                  value={searchKeyword}
                  type="search"
                  id="search-dropdown"
                  className="block p-2.5 w-full sm:w-60 md:w-80 z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search..."
                  required
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <button
                  type="submit"
                  className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                >
                  <BiSearch className="text-xl" />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="flex relative transition ease-in-out duration-1000">
          <button
            className="bg-[#F2F2F2] text-[#545454] p-3 mx-1 rounded-full hover:text-white hover:bg-[#234777] transform duration-500"
            onClick={toggleDropDown}
          >
            <BiSolidUser />
          </button>
          {isOpen && (
            <div
              ref={dropdownRef}
              className="absolute z-20 font-medium bg-white rounded-lg w-56 text-black top-11 right-2 py-2 shadow-sm shadow-black"
            >
              <Link to="/">
                <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                  <AiFillHome className="mr-2" /> {t("Home")}
                </div>
              </Link>
              {logIn ? (
                <>
                  <Link to="/account?component=AccountInfo">
                    <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                      <FaHouseUser className="mr-2" />
                      {t("Account Info")}
                    </div>
                  </Link>
                  {/* <Link to="/account?component=UpgradeToStore">
                    <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                      <FaIndustry className="mr-2" />
                      Upgrade
                    </div>
                  </Link> */}
                  <Link to="/my-store">
                    <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                      <FaIndustry className="mr-2" />
                      Store
                    </div>
                  </Link>
                  <Link to="/business-store">
                    <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                      <FaIndustry className="mr-2" />
                      Business
                    </div>
                  </Link>

                  <Link to="/favorite-ads">
                    <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b">
                      <AiOutlineHeart className="mr-2" />
                      Favorites
                    </div>
                  </Link>
                  <Link
                    to="/featured-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <BiDesktop className="mr-2" />
                    FEATURED ADS
                  </Link>
                  <Link
                    to="/sponsored-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <AiOutlineMenuFold className="mr-2" />
                    SPONSORED ADS
                  </Link>
                  <Link
                    to="/affiliate-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <BiDesktop className="mr-2" />
                    AFFILIATE ADS
                  </Link>
                  <Link
                    to="/classifieds-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <MdOutlineNewLabel className="mr-2" />
                    CLASSIFIEDS ADS
                  </Link>
                  <Link
                    to="/new-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <MdOutlineNewLabel className="mr-2" />
                    NEWS ADS
                  </Link>
                  <Link
                    to="/banner"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white"
                  >
                    <PiFlagBanner className="mr-2" />
                    BANNER ADVERTS
                  </Link>
                  <Link
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                    to="/Login"
                    onClick={() => {
                      toggleDropDown();
                      dispatch(logOut());
                    }}
                  >
                    <MdLogout className="mr-2" />
                    Logout
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/Login?tab=signup"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <FaPencilAlt className="mr-2" />
                    REGISTER
                  </Link>
                  <Link
                    to="/Login"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <FaUserAlt className="mr-2" />
                    LOGIN
                  </Link>
                  <Link
                    to="/featured-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <BiDesktop className="mr-2" />
                    FEATURED ADS
                  </Link>
                  <Link
                    to="/sponsored-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <AiOutlineMenuFold className="mr-2" />
                    SPONSORED ADS
                  </Link>
                  <Link
                    to="/affiliate-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <BiDesktop className="mr-2" />
                    AFFILIATE ADS
                  </Link>
                  <Link
                    to="/classifieds-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white"
                  >
                    <MdOutlineNewLabel className="mr-2" />
                    CLASSIFIEDS ADS
                  </Link>
                  <Link
                    to="/new-ads"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b"
                  >
                    <MdOutlineNewLabel className="mr-2" />
                    NEWS ADS
                  </Link>
                  <Link
                    to="/banner"
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white"
                  >
                    <PiFlagBanner className="mr-2" />
                    BANNER AFFILIATES
                  </Link>
                </>
              )}
            </div>
          )}
          {/* <button
            className="lg:flex items-center gap-2 mx-2 text-xs text-white font-semibold rounded-full lg:rounded-full lg:px-7 px-4 py-2 bg-[#234676] hover:bg-slate-500 transition duration-300 md:px-2 md:py-1"
            onClick={ShowModal}
          >
            <BsFillPlusCircleFill /> <span className="hidden lg:flex  ">POST NEW AD</span>
          </button> */}
          <button
            className="lg:flex items-center gap-2 mx-2 text-xs g:px-7 px-4 py-2 bg-gradient-to-r from-blue-900 to-blue-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg"
            onClick={ShowModal}
          >
            <BsFillPlusCircleFill />{" "}
            <span className="hidden lg:flex  ">POST NEW AD</span>
          </button>
          {/* <LanguageSelector /> */}
          {/* <img src="/images/user.png" alt="user" className="w-8 bg-white rounded-2xl"  /> */}
        </div>
      </div>
      <div className="w-10/12 text-lg mx-auto pb-5 relative sm:hidden md:flex lg:hidden ">
        <form className="w-full" onSubmit={handleSearch}>
          <div className="flex">
            {/* <div className="relative flex-shrink-0 inline-flex">
              <div className="flex">
                <button 
                  id="dropdown-button" 
                  data-dropdown-toggle="dropdown" 
                  className="flex-shrink-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100" 
                  type="button"
                  onClick={() => setIsOpenCategorySearch(!isOpenCategorySearch)}
                >
                  {searchCategorySelected.text}
                  <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
              </div>
              {isOpenCategorySearch && (
                <div ref={dropdownRefSearch} className="absolute bg-white divide-y top-11 divide-gray-100 rounded-lg shadow w-44">
                  <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdown-button">
                    <li>
                      <button 
                        onClick={() => {
                          setSearchCategorySelected({
                            text: 'All categories',
                            value: '',
                          })
                          setIsOpenCategorySearch(false)
                        }} 
                        type="button" 
                        className="inline-flex w-full px-4 py-2 hover:bg-gray-100"
                        >
                          All categories
                      </button>
                    </li>
                    {categoryAds.items?.map((item, index) => (
                      <li key={index}>
                        <button 
                          onClick={() => {
                            setSearchCategorySelected({
                              text: item.name,
                              value: item.slug,
                            })
                            setIsOpenCategorySearch(false)
                          }} 
                          type="button" 
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100"
                        >
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div> */}
            <div className="relative flex-shrink-0 inline-flex">
              <div className="flex">
                <Link
                  id="dropdown-button"
                  data-dropdown-toggle="dropdown"
                  className="flex-shrink-0 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                  // type="button"
                  // onClick={() => setIsOpenCategorySearch(!isOpenCategorySearch)}
                  to={"/category-menu"}
                >
                  <IoMdList className="h-5 w-5 rounded-full" />
                  {/* {searchCategorySelected.text}
                    <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                    </svg> */}
                </Link>
              </div>
            </div>
            <div className="relative w-full">
              <input
                type="search"
                id="search-dropdown"
                className="block p-2.5 w-full sm:w-60 md:w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search..."
                required
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button
                type="submit"
                className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
              >
                <BiSearch className="text-xl" />
              </button>
            </div>
          </div>
        </form>
      </div>
      {showModal && (
        <div className="w-full absolute top-0 h-screen bg-[rgba(0,0,0,0.6)] overflow-auto">
          <span
            className="absolute right-5 top-16 text-3xl text-white cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <MdCancel />
          </span>
          <div className="bg-white w-10/12 relative top-24 m-auto flex flex-wrap gap-2 justify-between lg:justify-normal rounded p-5">
            {/* <div className="bg-red-300 w-5/6 relative top-24 m-auto grid justify-between grid-cols-1 sm:grid-cols-1 md:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-5"> */}
            {categoryAds.items?.map((item, index) => (
              <Link
                key={item.category_id}
                to={`/post/${item.slug}/${item.category_id}`}
                onClick={() => setShowModal(false)}
              >
                <div
                  key={index}
                  className="sm:w-[280px] lg:w-[265px] xl:w-[245px] relative flex rounded justify-center items-center mb-5"
                >
                  {/* {item.images?.length > 0 ? (
                    <img
                      src={item.Image}
                      alt="Category"
                      className="brightness-50 contrast-100 rounded "
                    />
                  ) : (
                    <img
                      src="/img/NoImage1.png"
                      alt="Default"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                  <p className="absolute text-black  font-bold text-xl ">
                    {item.name}
                  </p> */}
                  <div className="flex w-full items-center p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-slate-50">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                      {getIcon(item.icon)}
                    </div>
                    <div>
                      <p className="mb-2 text-xl font-medium text-gray-600">
                        {item.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            <Link
              key={"banner"}
              to={`/postbanner`}
              onClick={() => setShowModal(false)}
            >
              <div className="sm:w-[280px] lg:w-[265px] xl:w-[245px] relative flex rounded justify-center items-center mb-5">
                <div className="flex w-full items-center p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-slate-50">
                  <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <PiFlagBanner />
                  </div>
                  <div>
                    <p className="mb-2 text-xl font-medium text-gray-600">
                      Banner
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
      {showSideBar && (
        <div className="w-full h-screen absolute top-0 bg-[rgba(0,0,0,0.6)] ">
          <div className="w-96 h-screen bg-white  overflow-y-scroll">
            <div className="h-20 border flex items-center  justify-between pl-4 pr-4">
              <div>
                <img className="mb-2" src="/img/wwaLogo.png" alt="" />
                <div className="flex gap-1 items-center text-gray-600">
                  <MdLocationPin />{" "}
                  {country && (
                    <span>
                      {city}, {country}
                    </span>
                  )}
                </div>
              </div>
              <p className="h-8 w-8 text-red-800 flex items-center justify-center rounded-full shadow-sm hover:bg-red-800 group white transition duration-700 ">
                <ImCross
                  onClick={toggleSideBar}
                  className="group-hover:text-white transition duration-700 "
                />
              </p>
            </div>
            <div className="p-4 text-lg font-semibold">
              {categoryAds.items?.map((item, index) => (
                <Link
                  key={item.category_id}
                  to={`/category/${item.slug}`}
                  onClick={toggleSideBar}
                >
                  <div
                    key={index}
                    className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b "
                  >
                    {/* <AiFillHome className="mr-2" /> */}
                    <i className={item.icon}></i>
                    {item.name}
                  </div>
                </Link>
              ))}
              <Link to={`/book/`} onClick={toggleSideBar}>
                <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b ">
                  Book
                </div>
              </Link>
              <Link to={`/banner/`} onClick={toggleSideBar}>
                <div className="flex items-center p-1 px-4 text-[rgb(35,71,119)] hover:bg-[#234777] hover:text-white border-b ">
                  Banner Adverts
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { BiExitFullscreen } from "react-icons/bi";
import { FaList, FaRegStar, FaStar, FaStore, FaTags } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { creatFavouriteAds, getGlobalSearch } from "../slice/ListSlice";
import Loading from "../Component/Loading";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IoMdBusiness } from "react-icons/io";
import { PiFlagBanner } from "react-icons/pi";
import BannerDetail from "./BannerDetail";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const StoreCard = ({ store }) => {
  return (
    <Link to={`/store/${store.customer_id}`} className="flex w-full group">
      <div className="flex flex-col w-full max-w-sm rounded-lg overflow-hidden shadow-lg bg-white p-4">
        <img
          height="120"
          width="120"
          src={
            store?.store_logo
              ? store?.store_logo
              : "/img/no-image-available.jpg"
          }
          // src="/img/noImage.png"
          className={`w-full h-48 ${
            store?.store_logo ? "object-contain" : "object-cover"
          }`}
          alt="Company Logo"
        />
        <div className="px-6 py-4 flex flex-col justify-center">
          <div className="font-bold text-xl mb-2 group-hover:text-yellow-500">
            {store.store_name}
          </div>
          <p className="text-gray-700 text-base">{store.store_address}</p>
        </div>
        {/* <div className="px-6 pt-4 pb-2">
        {store.tags?.map((tag, index) => (
          <span
            key={index}
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            #{tag}
          </span>
        ))}
      </div> */}
      </div>
    </Link>
  );
};
const BusinessCard = ({ business }) => {
  return (
    <Link to={`/business/${business.slug}`} className="flex group">
      <div className="flex flex-1 flex-col max-w-sm rounded-lg overflow-hidden shadow-lg bg-white p-4">
        <img
          height="120"
          width="120"
          src={
            business?.business_logo
              ? business?.business_logo
              : "/img/no-image-available.jpg"
          }
          className={`w-full h-48 ${
            business?.business_logo ? "object-contain" : "object-cover"
          }`}
          alt="Company Logo"
        />
        <div className="px-6 py-4 flex flex-col justify-center">
          <div className="font-bold text-xl mb-2 group-hover:text-yellow-500">
            {business.business_name}
          </div>
          <p className="text-gray-700 text-base">{business.business_address}</p>
        </div>
      </div>
    </Link>
  );
};

const AllSearchResult = () => {
  const dispatch = useDispatch();
  const { searchValue } = useParams();
  const loading = useSelector((store) => store.ads.loading);
  const searchData = useSelector((store) => store.ads.globalSearch);
  const searchDataList = searchData?.data || [];

  const itemsPerPage = 10;
  const totalDataCount = searchData?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [liked, setLiked] = useState(Array(searchData.length).fill(false));
  const [dataBanner, setDataBanner] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!searchValue) return;
    dispatch(getGlobalSearch({ searchData: { keyword: searchValue } }));
  }, [searchValue]);

  const toggleLike = (index) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const [singleColumnView, setSingleColumnView] = useState(true);
  const [multipleColumnView, setMultipleColumnView] = useState(false);

  // function for grid view
  const handleSingleColumnView = () => {
    setSingleColumnView(true);
    setMultipleColumnView(false);
  };

  const handleMultipleColumnView = () => {
    setSingleColumnView(false);
    setMultipleColumnView(true);
  };

  const totalPages = Math.ceil(searchData.length / itemsPerPage);

  // useEffect(() => {
  //   dispatch(
  //     getFeaturedAds({
  //       skip: (currentPage - 1) * itemsPerPage,
  //       limit: itemsPerPage,
  //     })
  //   );
  // }, [currentPage]);

  const truncateDescription = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const addToFavourite = (customer_id, listing_id, index) => {
    if (liked[index]) {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: false },
        })
      );
    } else {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: true },
        })
      );
    }

    setLiked((prevLiked) => ({
      ...prevLiked,
      [index]: !prevLiked[index],
    }));
  };
  useEffect(() => {
    if (searchDataList?.banner?.items?.length) {
      const dataMap = searchDataList?.banner?.items.map((item) => {
        return {
          ...item,
          src: item.img,
        };
      });
      setDataBanner([...dataMap]);
    } else {
      setDataBanner([]);
    }
  }, [searchDataList]);
  return (
    <div className=" relative pt-32 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row  sm:flex justify-between py-5">
        <h1 className="text-blue-500 font-bold text-4xl">
          <span className="text-[#234777]">All</span>
          <span className="text-[#01C6DA]"> Results</span>
        </h1>
      </div>
      <hr className="border border-[#234777] mb-3" />
      {loading ? (
        <Loading />
      ) : (
        <Tabs>
          <TabList>
            <Tab>
              <div className="flex gap-2 items-center">
                <FaList />
                Ads
              </div>
            </Tab>
            <Tab>
              <div className="flex gap-2 items-center">
                <PiFlagBanner />
                Banner Ads
              </div>
            </Tab>
            <Tab>
              <div className="flex gap-2 items-center">
                <FaStore />
                Store
              </div>
            </Tab>
            <Tab>
              <div className="flex gap-2 items-center">
                <IoMdBusiness />
                Business
              </div>
            </Tab>
          </TabList>

          <TabPanel>
            {searchDataList?.listing?.items?.length > 0 ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 sm:justify-center md:grid-cols-2 lg:grid-cols-2 lg:justify-between mb-5">
                {searchDataList.listing.items?.map((item, index) => (
                  <div
                    className="group w-full flex overflow-hidden border border-slate-200 shadow-lg rounded-lg"
                    key={index}
                  >
                    {/* <Link to={`/ads-detail/${item.slug}`}> */}
                    <Link
                      to={`/ads-detail/${item.slug}`}
                      className="w-1/3 h-56 bg-slate-100 flex items-center"
                    >
                      {item.images.length > 0 ? (
                        <img
                          src={item.images[0].image_path}
                          alt={item.title}
                          onError={(e) => {
                            e.target.src = "/img/no-image-available.jpg";
                          }}
                          className="object-cover"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src="/img/no-image-available.jpg"
                          alt="Default"
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </Link>
                    {/* </Link> */}
                    <div className="w-7/12 p-4">
                      <div className="flex items-center gap-1 text-[#999999] pb-2">
                        <div>
                          <FaTags />
                        </div>
                        <div>{item.category.name}</div>
                      </div>
                      <div>
                        <hr className="border-1 text-[#999999]" />
                      </div>
                      <div className="text-lg text-[#232D3B] py-1">
                        <Link
                          to={`/ads-detail/${item.slug}`}
                          className="font-bold group-hover:text-yellow-500"
                        >
                          {item.title}
                        </Link>
                      </div>
                      <div className="flex items-start gap-1 pb-2 text-[#999999]">
                        <div className="pt-1">
                          <MdLocationOn />
                        </div>
                        <div>{item.location.city}</div>
                      </div>
                      <div>
                        <hr className="border-1 text-[#999999]" />
                      </div>
                      <div className="flex justify-between align-middle font-bold text-[#232D3B] text-lg py-2">
                        <div className="font-bold text-[#232D3B] text-lg py-2">
                          <div>
                            {item.currency.symbol} {item.price}
                          </div>
                        </div>
                        <div className=" w-1/4 flex justify-end">
                          <div className=" border-slate-200">
                            <Link to={`/ads-detail/${item.slug}`} key={index}>
                              <button className="p-1 hover:scale-110 duration-300">
                                <BiExitFullscreen className="w-5 h-5" />
                              </button>
                            </Link>
                          </div>
                          <span className="border max-h-[70%]"></span>
                          <div className="  border-slate-300">
                            <button
                              className="p-1 hover:scale-110 duration-300"
                              onClick={() =>
                                addToFavourite(
                                  item.customer_id,
                                  item.listing_id,
                                  index
                                )
                              }
                            >
                              {liked[index] ? (
                                <FaStar className="text-yellow-400 w-5 h-5" />
                              ) : (
                                <FaRegStar className="w-5 h-5" />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
                <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
                  {" "}
                  <span className="text-2xl italic">No results found</span>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            {dataBanner?.length > 0 ? (
              <div>
                <div className="min-h-screen flex flex-col">
                  <div className="flex-grow flex flex-col justify-between">
                    <InfiniteScroll
                      dataLength={dataBanner.length}
                      hasMore={false}
                      loader={
                        <div style={{ height: "200px" }}>
                          {loading && (
                            <div className="flex gap-5">
                              <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                                  <svg
                                    className="w-10 h-10 text-gray-200"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                                  <svg
                                    className="w-10 h-10 text-gray-200"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                                  <svg
                                    className="w-10 h-10 text-gray-200"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <ResponsiveMasonry
                        columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4 }}
                      >
                        <Masonry gutter="20px">
                          {dataBanner.map((item, i) => (
                            <button
                              onClick={() => {
                                setSelectedData(item);
                                setIsOpen(true);
                              }}
                              key={i}
                              className="w-full block overflow-hidden"
                            >
                              <img
                                className="w-full rounded-md h-auto max-h-[500px] object-cover"
                                src={item.img}
                                alt={item.title}
                              />
                            </button>
                          ))}
                        </Masonry>
                      </ResponsiveMasonry>
                    </InfiniteScroll>
                  </div>
                </div>
                <BannerDetail
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  data={selectedData}
                />
              </div>
            ) : (
              <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
                <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
                  {" "}
                  <span className="text-2xl italic">No results found</span>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            {searchDataList?.store?.items?.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-5">
                {searchDataList?.store.items.map((store, index) => (
                  <StoreCard key={index} store={store} />
                ))}
              </div>
            ) : (
              <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
                <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
                  {" "}
                  <span className="text-2xl italic">No results found</span>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            {searchDataList?.business?.items?.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-5">
                {searchDataList?.business.items.map((store, index) => (
                  <BusinessCard key={index} business={store} />
                ))}
              </div>
            ) : (
              <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
                <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
                  {" "}
                  <span className="text-2xl italic">No results found</span>
                </div>
              </div>
            )}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default AllSearchResult;

import React, { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Navbar from "../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createAdsList } from "../../slice/ListSlice";
import { CategoryTreeChild, getCurrency } from "../../slice/CategorySlice";

function PostEvents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug, id } = useParams();
  const initialFormState = {
    title: "",
    Image: null,
    video: null,
    category: "",
    currency: "Select Currency",
    price: "",
    description: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [activeInput, setActiveInput] = useState(null);

  const categoryAdsData = useSelector((store) => store.categories.catTreeChild);
  const SubCatPost = categoryAdsData?.data || [];

  const catMasterData = useSelector((store) => store.categories.currency);
  const CatMaster = catMasterData?.data || [];

  console.log(".......>>>>", CatMaster);

  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleInputBlur = () => {
    setActiveInput(null);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormState({
        ...formState,
        [name]: files[0],
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    dispatch(getCurrency());
  }, []);
  useEffect(() => {
    dispatch(CategoryTreeChild({ id }));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formState.title === "" ||
      formState.category === "" ||
      formState.currency === "Select Currency" ||
      formState.price === "" ||
      formState.description === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    if (formState.video && formState.Image) {
      alert("Please select either an image or a video, not both.");
      return;
    }
    if (parseFloat(formState.price) < 0) {
      alert("Price cannot be negative.");
      return;
    }
    // console.log(formState);
    navigate("/subscription");
    // dispatch(createAdsList({ formData: formState }));
  };

  return (
    <div>
      <Navbar />
      <div
        className="w-full bg-cover bg-center"
        style={{ backgroundImage: `url(/img/form-bg-1.jpg)` }}
      >
        <div className="pt-32 lg:pt-20 w-full flex flex-col justify-center items-center gap-5 bg-black bg-opacity-50">
          <form
            className="w-11/12 md:w-6/12 bg-white p-4"
            onSubmit={handleSubmit}
          >
            <div className="full border-2 border-slate-300 shadow-lg p-4">
              <div className="text-[#234777] text-4xl font-bold py-4">
                {slug.toUpperCase()}
              </div>
              <div>
                <hr></hr>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">Title</div>
                <div>
                  <input
                    type="text"
                    name="title"
                    value={formState.title}
                    placeholder="Type Your Product Title Here"
                    onFocus={() => handleInputFocus("title")}
                    onBlur={handleInputBlur}
                    onChange={handleChange}
                    className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                      activeInput === "title"
                        ? "border-b-2 border-blue-500"
                        : ""
                    }`}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">
                  Add Image
                </div>
                <div>
                  <input
                    type="file"
                    name="Image"
                    onChange={handleChange}
                    className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                    placeholder="Type Your Product Title Here"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-gray-500 font-semibold">
                  Add Video
                </div>
                <div className="bg-slate-200">
                  <input
                    type="file"
                    name="video"
                    onChange={handleChange}
                    className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                    accept="video/*"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="w-full">
                  <div className="py-2 text-[#234777] font-semibold">
                    SUB CATEGORY
                  </div>
                  <div className="w-full">
                    <select
                      name="category"
                      value={formState.category}
                      onChange={handleChange}
                      className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                    >
                      <option className="text-slate-600">
                        Select Sub Category"
                      </option>
                      {SubCatPost.items &&
                        SubCatPost.items[0]?.childs?.map((subCat, i) => {
                          return (
                            <option key={i} value={subCat.name}>
                              {subCat.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full gap-8 flex justify-between">
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">
                    Select Currency
                  </div>
                  <div className="w-full">
                    <select
                      name="currency"
                      value={formState.currency}
                      onChange={handleChange}
                      className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                    >
                      <option className="text-slate-600">
                        Select Currency
                      </option>
                      {CatMaster.items &&
                        CatMaster.items.map((currency, i) => {
                          return (
                            <option key={i} value={currency.name}>
                              {currency.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">Price</div>
                  <div>
                    <input
                      type="number"
                      name="price"
                      min="0"
                      value={formState.price}
                      onChange={handleChange}
                      className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                      placeholder="Enter Your Pricing Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">
                  Ad Description
                </div>
                <div>
                  <textarea
                    name="description"
                    value={formState.description}
                    onChange={handleChange}
                    rows="4"
                    cols="50"
                    className="w-full p-4 bg-slate-100 border-b-2 border-gray-300"
                    placeholder="Describe Your Message"
                  />
                </div>
              </div>
              <div className="w-full flex gap-10 py-5 text-center">
                <div className="w-1/2 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button>
                    <BsFillArrowLeftCircleFill />
                  </button>
                </div>
                <div className="w-1/2 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button type="submit">SUBMIT</button>
                </div>
                {/* <div className="w-1/3 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button>PREVIEW</button>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PostEvents;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateListTop } from "../slice/AffiliateSLice";
import { Link } from "react-router-dom";

const TopAffiliate = () => {
  const dispatch = useDispatch();
  const affiliateData = useSelector((store) => {
    return store.aff.affiliateListTop;
  });
  const affiliates = affiliateData?.data || [];

  const slideRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    slideRef.current.slickNext();
  };
  const prevSlide = () => {
    slideRef.current.slickPrev();
  };
  useEffect(() => {
    dispatch(getAffiliateListTop());
  }, []);
  // const truncateString = (str, maxLength) => {
  //   if (str.length > maxLength) {
  //     return str.substring(0, maxLength) + "...";
  //   }
  //   return str;
  // };
  return (
    <div className="w-full flex justify-center py-8 md:mt-5 sm:mt-[280px]">
      <div className="slider container mx-auto w-11/12 relative  ">
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-blue-500 font-bold text-2xl">
              <span className="text-[#234777]">Top</span>
              <span className="text-[#01C6DA]"> Affiliates</span>
            </h1>
            <Link to="/affiliate-ads">
              <button className="flex text-lg font-semibold text-[#234777] hover:text-[#01C6DA]">
                See All <FiChevronRight className="relative top-1.5 text-lg" />
              </button>
            </Link>
          </div>
          <div className="py-8">
            <Slider className="" ref={slideRef} {...settings}>
              {affiliates?.items?.map((items, index) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/affiliate?url=${items.link}`}
                  key={index}
                  className="p-4 max-w-sm"
                >
                  {items.image_url ? (
                    <div
                      key={index}
                      className="group w-full h-full overflow-hidden border border-slate-200 shadow-lg rounded-lg"
                    >
                      <div className="h-40 mb-2">
                        {items.image_url ? (
                          <img
                            className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                            src={items.image_url}
                            alt={items.title}
                            onError={(e) => {
                              e.target.src = "/img/no-image-available.jpg";
                            }}
                          />
                        ) : (
                          <img
                            className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                            src={"/img/no-image-available.jpg"}
                            alt="Default"
                          />
                        )}
                      </div>
                      <div className="px-4">
                        <div className="text-base text-[#232D3B] py-1 flex-grow h-[3.5rem]">
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            to={`/affiliate?url=${items.link}`}
                            className="text-black group-hover:text-yellow-500 inline-flex items-center"
                          >
                            <span className="line-clamp-2">{items.title}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="group w-full rounded-lg h-full bg-gradient-to-r from-blue-900 to-cyan-700 shadow-lg">
                      <div className="h-[226px] p-8">
                        <div className="flex flex-grow h-full items-center justify-center">
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            to={`/affiliate?url=${items.link}`}
                            className="text-base text-white text-ellipsis overflow-hidden group-hover:text-yellow-500 line-clamp-6"
                          >
                            {items.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </a>
              ))}
            </Slider>
          </div>
        </div>
        <button
          className="prev-button absolute top-1/2  text-3xl bg-transparent  transform -translate-y-1/2 -translate-x-3 border border-slate-200 rounded-2xl"
          onClick={prevSlide}
        >
          <FiChevronLeft />
        </button>
        <button
          className="next-button absolute  right-0  top-1/2  text-3xl transform -translate-y-1/2 translate-x-3  bg-transparent border border-slate-200 rounded-2xl"
          onClick={nextSlide}
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default TopAffiliate;

import React, { useEffect, useState } from "react";
import Cards from "../Component/Cards";
import PostNewAds from "../Component/PostNewAds";
import Footer from "../Component/Footer";
import Video from "../Component/Video";
import TopAffiliate from "../Component/TopAffiliate";
import Navbar from "../Component/Navbar";
import Loading from "../Component/Loading";

function Homepage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("hasVisitedHomepage")) {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem("hasVisitedHomepage", "true");
      }, 1000);
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Navbar />
          <Video />
          <TopAffiliate />
          <Cards />
          <PostNewAds />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Homepage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import { FaStar, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { AiFillHeart, AiFillMessage } from "react-icons/ai";
import { MdLocationOn, MdOutlineReportProblem } from "react-icons/md";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteAdsDetail } from "../../slice/ListSlice";
import GoogleAds from "../GoogleAds";
import GoogleApiData from "../../useEnv";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const googleMapsApiKey = GoogleApiData.GoogleApiKey;

function FavouriteAdsDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const featureAdsDetails = useSelector(
    (store) => store.ads.favouriteAdsDetail
  );
  const featuredAds = featureAdsDetails?.data || [];

  const [shareOverlay, setShareOverlay] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getFavouriteAdsDetail({ id }));
    }
  }, [id]);

  const handleShareButton = () => {
    setShareOverlay(true);
  };
  const closeShareOverlay = () => {
    setShareOverlay(false);
  };
  return (
    <div className="w-full">
      <Navbar />
      <div className="w-full pt-36 lg:pt-24 bg-white flex justify-center mb-10">
        <div className="w-11/12 flex flex-col gap-8">
          <div className="w-full flex flex-col lg:flex lg:flex-row gap-5 md:flex-row sm:flex-row">
            <div className="w-full lg:w-4/12 overflow-hidden border-2 shadow-lg rounded-lg md:w-1/2 ">
              <div className="w-full min-h-fit md:max-h-96 md:w-full">
                {featuredAds.listing?.images &&
                featuredAds.listing?.images?.length > 0 ? (
                  <img
                    src={featuredAds?.listing?.images[0]?.image_path}
                    className="w-full transition ease-in-out duration-700 group-hover:scale-110 transform hover:scale-105 object-fill lg:max-h-96 "
                    onError={(e) => {
                      e.target.src = "/img/no-image-available.jpg";
                    }}
                    alt="Featured Image"
                  />
                ) : (
                  <img
                    src="/img/no-image-available.jpg"
                    alt="Default"
                    className="w-full"
                  />
                )}
              </div>
            </div>
            <div className="w-full lg:w-8/12 border-2 shadow-lg rounded-lg md:w-3/4">
              <div className="p-4">
                <div className="text-2xl text-[#232D3B] font-semibold">
                  DETAILS
                </div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-500"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div className="text-xl lg:text-3xl text-[#234777] font-bold pb-4">
                  {featuredAds?.listing?.title}
                </div>
                <div className="text-2xl font-semibold text-[#01C6DA]">
                  {featuredAds?.listing?.price}{" "}
                  {featuredAds?.listing?.currency?.name}
                </div>
                <div className="text-xl font-medium flex gap-2 items-center py-4 text-slate-700">
                  <MdLocationOn />
                  {featuredAds?.listing?.location?.city},
                  {featuredAds?.listing?.location?.country_id}
                </div>
                <div className="py-2 flex gap-2 lg:gap-8">
                  <div>
                    <button className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300">
                      <span>
                        <FaStar />
                      </span>
                      <span>Favorites</span>
                    </button>
                  </div>
                  <div>
                    <button className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300">
                      <span>
                        <MdOutlineReportProblem />
                      </span>
                      <span>Report</span>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={handleShareButton}
                      className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300"
                    >
                      <span>
                        <AiFillMessage />
                      </span>
                      <span>Message</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-auto flex flex-col-reverse lg:flex lg:flex-row gap-5">
            <div className="w-full lg:w-8/12 rounded-lg">
              <div className="p-4 border-2 shadow-lg rounded-lg">
                <div className="text-2xl font-semibold">DESCRIPTION</div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-600"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div
                  className="font-medium text-slate-700"
                  dangerouslySetInnerHTML={{
                    __html:
                      featuredAds?.listing?.description?.replace(
                        /\\n/g,
                        "<br>"
                      ) || "",
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-4/12 border-2 shadow-lg rounded-lg">
              <div className="p-4">
                <div className="text-2xl font-semibold">AREA MAP</div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-500"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div className="py-4">
                  <iframe
                    title="Map"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${featuredAds?.listing?.location?.latitude},${featuredAds?.listing?.location?.longitude}`}
                    allowFullScreen
                  ></iframe>
                </div>
                <GoogleAds />
              </div>
            </div>
          </div>

          {shareOverlay && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              {/* <div className='w-72 h-60 md:w-1/2 lg:w-2/6 lg:h-1/2 bg-white flex justify-center rounded shadow-lg relative'> */}
              <div className="w-96 h-44 bg-white">
                <div className=" p-5">
                  <div className="flex items-center justify-between">
                    <div className=" text-xl font-medium">Message</div>
                    <div>
                      <ImCross onClick={() => setShareOverlay(false)} />
                    </div>
                  </div>
                  <div className="border-1 py-2">
                    <hr></hr>
                  </div>
                  <div className="flex justify-around gap-4 py-2">
                    <div className="flex flex-col items-center">
                      <div>
                        <button className="rounded-full py-4 px-4 bg-[#3B5998] text-lg text-white">
                          <BsFacebook style={{ fontSize: "25px" }} />
                        </button>
                      </div>
                      <div>Facebook</div>
                    </div>

                    <div className="flex flex-col items-center">
                      <button className="rounded-full py-4 px-4 bg-[#00ACEE] text-lg text-white">
                        <FaTwitter style={{ fontSize: "25px" }} />
                      </button>
                      <div>Twitter</div>
                    </div>

                    <div className="flex flex-col items-center">
                      <button className="rounded-full py-4 px-4 bg-[#0E76A8] text-lg text-white">
                        <FaTelegram style={{ fontSize: "25px" }} />
                      </button>
                      <div>Telegram</div>
                    </div>

                    <div className="flex flex-col items-center">
                      <div>
                        <button className="rounded-full py-4 px-4 bg-[#43BF51] text-lg text-white">
                          <FaWhatsapp style={{ fontSize: "25px" }} />
                        </button>
                      </div>
                      <div>Facebook</div>
                    </div>
                  </div>
                </div>
                {/* <div className='absolute top-4 right-4 text-lg text-red-600'> <ImCross  onClick={()=>setShareOverlay(false)}/></div>
                  <div className='w-64 md: xl:w-96 flex flex-col justify-center'>
                      <button onClick={closeShareOverlay} className='bg-blue-500 text-white py-2 px-4 hover:bg-blue-600'>Reset Password</button>
                  </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FavouriteAdsDetail;

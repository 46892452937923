import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEbayAds } from "../slice/CategorySlice";
import { useNavigate } from "react-router-dom";

function EbayAds() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EbayAdsData = useSelector((store) => store.categories.ebayAds);

  const handleNavigate = (url) => {
    navigate(url)
  };

  useEffect(() => {
    dispatch(getEbayAds());
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center mb-5">
      <div className="w-11/12 h-16 gap-1 flex justify-start text-2xl font-bold">
        <span className="" style={{ color: "#253776" }}>
          Ebay
        </span>
        <span></span>
        <span className="" style={{ color: "#00C6DB" }}>
          Ads
        </span>
      </div>

      <div className="w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {EbayAdsData.data?.items?.map((item, index) => (
          <div
            key={item.id}
            className="rounded-lg overflow-hidden  drop-shadow-lg"
            style={{
              backgroundColor: item.style.split(";")[0].split(":")[1].trim(),
              color: item.style.split(";")[1].split(":")[1].trim(),
               border: item.style?.includes("border-color") ? `2px solid ${item.style.split("border-color:")[1].split(";")[0].trim()}` : "",
            }}
          >
            <div>
              <img src={item.image_url}></img>
            </div>
            <div className="p-4">
              <div className="py-1 text-lg font-medium   ">
                <button
                  className=" py-1 px-3 rounded-md border "
                  onClick={() => window.location.href=item.url}
                >
                  {item.group_name}
                </button>
              </div>
              <div className="py-1 text-xl font-semibold">
                <h3>{item.title}</h3>
              </div>
              <div className="py-1">
                <p>{item.description}</p>
              </div>
              <div className="py-1 font-medium">
                <p>{item.expired_at}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EbayAds;

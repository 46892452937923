import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryServices from "../services/CategoryServices";

const initialState = {
  loading: false,
  categoryList: [],
  detailsCategory: [],
  catTree: [],
  ebayAds: [],
  catTreeChild: [],
  currency: [],
  country: [],
  zone: [],
  catFilter: [],
  message: null,
  error: null,
};

export const getCategoriesList = createAsyncThunk(
  "categories/getCategoryList",
  async ({ is_parent }) => {
    const res = await CategoryServices.getCategoriesList(is_parent);
    return res.data;
  }
);
export const getEbayAds = createAsyncThunk(
  "categories/getEbayAds",
  async () => {
    const res = await CategoryServices.getEbayAds();
    return res.data;
  }
);

export const createCategoryList = createAsyncThunk(
  "categories/createCategoryList",
  async (data) => {
    const res = await CategoryServices.createCategoryList(data);
    return res.data;
  }
);

export const detailsCategory = createAsyncThunk(
  "categories/detailCategory",
  async ({ slug }) => {
    const res = await CategoryServices.detailsCategory(slug);
    return res.data;
  }
);
export const CategoryTreeChild = createAsyncThunk(
  "categories/tree",
  async ({ id }) => {
    const res = await CategoryServices.CategoryTreeChild(id);
    return res.data;
  }
);
export const getFilterCat = createAsyncThunk(
  "categories/filterCat",
  async () => {
    const res = await CategoryServices.getFilterCat();
    return res.data;
  }
);
export const getCurrency = createAsyncThunk("categories/Currency", async () => {
  const res = await CategoryServices.getCurrency();
  return res.data;
});
export const getCountry = createAsyncThunk("categories/Country", async () => {
  const res = await CategoryServices.getCountry();
  return res.data;
});
export const getZone = createAsyncThunk("categories/Zone", async (payload) => {
  const res = await CategoryServices.getZone(payload);
  return res.data;
});

const handleError = (state, action) => {
  console.error(action.error.message, action.error);
  state.error = action.error.message;
};

const CategorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearAdsErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getCategoriesList.pending]: (state) => {
      state.loading = true;
    },
    [getCategoriesList.fulfilled]: (state, action) => {
      state.categoryList = action.payload;
      // state.loading = false;
    },
    [getCategoriesList.rejected]: handleError,
    [getEbayAds.fulfilled]: (state, action) => {
      state.ebayAds = action.payload;
      // state.loading = false;
    },
    [getEbayAds.rejected]: handleError,
    [createCategoryList.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [createCategoryList.rejected]: handleError,
    [detailsCategory.fulfilled]: (state, action) => {
      state.detailsAds = action.payload;
    },
    [detailsCategory.rejected]: handleError,

    [CategoryTreeChild.fulfilled]: (state, action) => {
      state.catTreeChild = action.payload;
    },
    [CategoryTreeChild.rejected]: handleError,
    [getFilterCat.fulfilled]: (state, action) => {
      state.catFilter = action.payload;
    },
    [getFilterCat.rejected]: handleError,
    [getCurrency.fulfilled]: (state, action) => {
      state.currency = action.payload;
    },
    [getCurrency.rejected]: handleError,
    [getCountry.fulfilled]: (state, action) => {
      state.country = action.payload;
    },
    [getCountry.rejected]: handleError,
    [getZone.fulfilled]: (state, action) => {
      state.zone = action.payload;
    },
    [getZone.rejected]: handleError,
  },
});

export const { clearAdsErrorAndMessage, redirectFalse } = CategorySlice.actions;
const { reducer } = CategorySlice;
export default reducer;

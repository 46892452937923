import React, { useState } from "react";
import GeneralInformation from "./GeneralInformation";
import UpgradeToStore from "./UpgradeToStore";
import UpgradeToBusinessStore from "./UpgradeToBusinessStore";
import { MdAddBusiness, MdHome } from "react-icons/md";
import { FaListUl, FaUser } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import AccountSettings from "./AccountSettings";
import ChangePassword from "./ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUserAvatar } from "../slice/AuthSlice";
import toast from "react-hot-toast";

const AccountInfo = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector((store) => store.auth?.userDetail?.data);

  const placeholderImageUrl = "/img/profile.png";
  const [activeSection, setActiveSection] = useState("General Information");
  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };
  const updateAvatar = async (file) => {
    console.log(file);
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        await dispatch(
          updateUserAvatar({
            id: userDetails.customer_id,
            payload: {
              avatar: reader.result,
            },
          })
        ).unwrap();
        toast.success("Data has been updated");
        dispatch(getUserDetails());
      } catch (error) {
        toast.error(error?.message || error);
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className="flex max-w-[85%] mx-auto my-7 space-x-6">
      <div className=" shadow-2xl rounded-xl w-1/4   ">
        <div className="flex flex-col items-center justify-center my-2 h-full max-h-[41%] gap-3 border-b">
          <div
            className="rounded-full overflow-hidden max-w-[10vw]"
            onClick={handleImageClick}
          >
            <img
              src={userDetails?.avatar || placeholderImageUrl}
              alt="User Avatar"
              className="object-contain w-full h-full cursor-pointer"
            />
          </div>
          <div className="text-[#77778D] text-2xl">Click to change picture</div>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                updateAvatar(file);
              }
            }}
          />
        </div>
        <div className="text-center max-h-[20%] h-full gap-3">
          <div className="my-2 text-[#5A7391] text-lg font-semibold">
            {userDetails?.first_name} {""} {userDetails?.last_name}
          </div>
          <div className="my-2 text-[#5c9ec4] font-semibold text-xs">
            CUSTOMER
          </div>
          <button className="text-white text-xs font-medium bg-[#5CB85C] px-5 py-3 rounded-md">
            {userDetails?.status}
          </button>
        </div>
        <div className="max-h-[20%] h-full">
          <div className="mt-4">
            <button
              onClick={() => setActiveSection("General Information")}
              className={`flex items-center gap-3 font-semibold text-slate-500 text-base border-b w-full p-2 ${
                activeSection === "General Information"
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
            >
              <MdHome /> General Information
            </button>
            <button
              onClick={() => setActiveSection("Account Settings")}
              className={`flex items-center gap-3 font-semibold text-slate-500 text-base border-b w-full p-2 ${
                activeSection === "Account Settings"
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
            >
              <FaUser /> Account Settings
            </button>
            <button
              onClick={() => setActiveSection("Change Password")}
              className={`flex items-center gap-3 font-semibold text-slate-500 text-base border-b w-full p-2 ${
                activeSection === "Change Password"
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
            >
              <TiTick /> Reset Password
            </button>
            <button
              onClick={() => setActiveSection("Upgrade to Store")}
              className={`flex items-center gap-3 font-semibold text-slate-500 text-base border-b w-full p-2 ${
                activeSection === "Upgrade to Store"
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
            >
              <FaListUl /> Upgrade to Store
            </button>
            <button
              onClick={() => setActiveSection("Upgrade to Business Store")}
              className={`flex items-center gap-3 font-semibold text-slate-500 text-base border-b w-full p-2 ${
                activeSection === "Upgrade to Business Store"
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
            >
              <MdAddBusiness /> Upgrade to Business Store
            </button>
          </div>
        </div>
      </div>
      <hr className="border-2 border-gray-400 my-4" />
      <div
        className=" shadow-2xl rounded-xl w-2/3 p-3 space-y-4 overflow-auto"
        style={{ height: "70vh" }}
      >
        {activeSection === "General Information" && <GeneralInformation />}
        {activeSection === "Account Settings" && <AccountSettings />}
        {activeSection === "Change Password" && <ChangePassword />}
        {activeSection === "Upgrade to Store" && <UpgradeToStore />}
        {activeSection === "Upgrade to Business Store" && (
          <UpgradeToBusinessStore />
        )}
      </div>
    </div>
  );
};

export default AccountInfo;

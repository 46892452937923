import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthServices from "../services/AuthServices";

// initialize userToken from local storage
const logIn = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const initialState = {
  loading: false,
  userInfo: null,
  redirect: false,
  authError: null,
  authMessage: null,
  logIn,
  userDetail: null,
  customerId: null,
};

export const signIn = createAsyncThunk(
  "v1/auth/login",
  async ({ formData }, thunkAPI) => {
    const res = await AuthServices.signIn(formData);
    return res.data;
  }
);
export const signUp = createAsyncThunk(
  "v1/auth/register",
  async ({ formData }) => {
    const res = await AuthServices.signUp(formData);
    return res.data;
  }
);

export const logOut = createAsyncThunk("auth/logout", async () => {
  const res = await AuthServices.logOut();
  return res.data;
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }) => {
    const res = await AuthServices.forgotPassword(email);
    return res.data;
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async () => {
    const res = await AuthServices.getUserDetails();
    return res.data;
  }
);

export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async ({ id, payload }) => {
    const res = await AuthServices.updateUserDetails(id, payload);
    return res.data;
  }
);
export const updateUserAvatar = createAsyncThunk(
  "auth/updateUserAvatar",
  async ({ id, payload }) => {
    const res = await AuthServices.updateUserAvatar(id, payload);
    return res.data;
  }
);

const handleError = (state, action) => {
  state.loading = false;
  console.error(action.error.message, action.error);
  state.authError = action.error.message;
  if (state.authError.includes("Unauthenticated.")) {
    localStorage.clear();
    state.logIn = null;
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearAuthErrorAndMessage: (state) => {
      state.authError = null;
      state.authMessage = null;
      localStorage.clear();
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, action) => {
      localStorage.setItem("token", action.payload.data.access_token);
      state.loading = false;
      state.logIn = true;
      state.redirect = true;
      state.authError = null;
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      state.authError = action.error.message;
      state.logIn = false;
    },
    [logOut.fulfilled]: (state, action) => {
      state.authMessage = action.payload.response;
      localStorage.clear();
      state.loading = false;
      state.userInfo = null;
      state.logIn = false;
    },
    [logOut.rejected]: (state, action) => {
      state.authError = action.error.message;
    },
    [signUp.fulfilled]: (state, action) => {
      state.authMessage = action.payload.response;
      state.authError = null;
    },
    [signUp.rejected]: (state, action) => {
      state.authError = action.error.message;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.authMessage = action.payload.response;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.authError = action.error.message;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.userDetail = action.payload;
      state.customerId = action.payload.data.customer_id;
      localStorage.setItem("customer_id", action.payload.data.customer_id);
      // state.loading = false;
    },
    [getUserDetails.rejected]: handleError,

    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      // state.loading = false;
    },
    [updateUserDetails.rejected]: handleError,

    [updateUserAvatar.pending]: (state) => {
      state.loading = true;
    },
    [updateUserAvatar.fulfilled]: (state, action) => {
      state.loading = false;
      // state.loading = false;
    },
    [updateUserAvatar.rejected]: handleError,
  },
});

// export actions
export const { clearAuthErrorAndMessage, redirectFalse } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  signIn,
  signUp,
  clearAuthErrorAndMessage,
  getUserDetails,
} from "../slice/AuthSlice";
import { clearAdsErrorAndMessage } from "../slice/StoreSlice";

import toast from "react-hot-toast";
import { auth } from "../firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";

function Signin(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authError } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [emailForget, setEmailForget] = useState("");

  const [showOverlay, setShowOverlay] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgotPasswordClick = () => {
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(signIn({ formData })).unwrap();
      await dispatch(getUserDetails()).unwrap();
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
    // if (authError === null) {
    //   navigate("/")
    // }
  };

  const handlePasswordChange = () => {
    // console.log("asdasd", emailForget);
    dispatch(forgotPassword({ emailForget }));
    closeOverlay();
    navigate("/Login");
  };
  useEffect(() => {
    // console.log('asdasd', authError)
    dispatch(clearAuthErrorAndMessage());
    dispatch(clearAdsErrorAndMessage());
  }, [dispatch]);

  // Social Media Sign In
  const handleSignUpAndSignIn = async (payload) => {
    try {
      await dispatch(signUp({ formData: payload })).unwrap();
      setTimeout(async () => {
        await dispatch(
          signIn({
            formData: { email: payload.email, password: payload.password },
          })
        ).unwrap();
        await dispatch(getUserDetails()).unwrap();
        navigate("/");
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handlingAfterSocialMediaSignIn = async (result) => {
    try {
      const payload = { email: result.user.email, password: result.user.uid };
      await dispatch(signIn({ formData: payload })).unwrap();
      await dispatch(getUserDetails()).unwrap();
      navigate("/");
    } catch (error) {
      if (error.message === "Data not found.") {
        const fullName = result.user.displayName.trim();
        const nameParts = fullName.split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");
        const payloadSign = {
          first_name: firstName,
          last_name: lastName ?? firstName,
          email: result.user.email,
          password: result.user.uid,
          password_confirmation: result.user.uid,
        };
        handleSignUpAndSignIn(payloadSign);
      } else {
        toast.error(error.message);
      }
    }
  };
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await handlingAfterSocialMediaSignIn(result);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await handlingAfterSocialMediaSignIn(result);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className="w-full flex justify-center">
      <div className="w-10/12">
        <form onSubmit={handleSubmit}>
          <div className="full text-center pt-8 pb-8">
            <div>
              <h1 className="text-4xl font-bold pb-5">Welcome!</h1>
            </div>
            <div>Use credentials to access your account.</div>
          </div>
          <div className="w-full flex flex-col justify-between">
            <div className="w-full pb-8">
              <input
                className="w-full h-12 border-b-2 border-slate-300"
                type="email"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleInputChange}
                required
                autoComplete="username"
              />
            </div>
            <div className="w-full pb-8">
              <input
                className="w-full h-12 border-b-2 border-slate-300"
                type="password"
                name="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                required
                autoComplete="current-password"
              />
            </div>
          </div>
          <div className="flex justify-between pt-2 pb-2">
            <div className="font-medium">
              <input type="checkbox"></input>
              <span className="pl-2">Remember me</span>
            </div>
            <div>
              <Link
                to="#"
                onClick={handleForgotPasswordClick}
                className="font-medium text-red-800 hover:underline"
              >
                Forgot password
              </Link>
            </div>
          </div>
          <div className="w-full flex items-center justify-center pt-2 pb-8">
            <button
              className="rounded-lg w-full text-md font-semibold bg-blue-500 text-white hover:bg-blue-600 pt-3 pb-3"
              type="submit"
            >
              LOGIN
            </button>
          </div>
          <div className="my-2">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-100 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-4 gap-3">
              <div>
                <a
                  href="#"
                  onClick={handleGoogleLogin}
                  className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="15"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  href="#"
                  // onClick={handleFacebookLogin}
                  className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="15"
                    viewBox="0 0 48 48"
                  >
                    <linearGradient
                      id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1"
                      x1="9.993"
                      x2="40.615"
                      y1="9.993"
                      y2="40.615"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#2aa4f4"></stop>
                      <stop offset="1" stop-color="#007ad9"></stop>
                    </linearGradient>
                    <path
                      fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)"
                      d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"
                    ></path>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="15"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="15"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#0078d4"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5	V37z"
                    ></path>
                    <path
                      d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364	c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274	L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479	C18,16.523,16.521,18,14.485,18H18v19H11z"
                      opacity=".05"
                    ></path>
                    <path
                      d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677	c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638	c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001	c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z"
                      opacity=".07"
                    ></path>
                    <path
                      fill="#fff"
                      d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12	c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698	c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19	c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center text-center">
            <div className="w-full mx-10 text-lg font-medium text-gray-500">
              Don't have an account? <br /> Click on the{" "}
              <span className="text-blue-600">
                <Link onClick={props.showSignupForm}> ( Sign Up )</Link>
              </span>{" "}
              button above
            </div>
          </div>
        </form>
        {showOverlay && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-72 h-60 md:w-1/2 lg:w-2/6 lg:h-1/2 bg-white flex justify-center rounded shadow-lg relative">
              <div className="absolute top-4 right-4 text-lg text-red-600">
                <ImCross onClick={closeOverlay} />
              </div>
              <div className="w-64 md: xl:w-96 flex flex-col justify-center">
                <input
                  type="email"
                  className="w-full border border-grey-300 mb-5 px-2 py-2"
                  placeholder="Enter Your Email"
                  value={emailForget}
                  onChange={(e) => {
                    setEmailForget(e.target.value);
                  }}
                />
                <button
                  onClick={handlePasswordChange}
                  className="bg-blue-500 text-white py-2 px-4 hover:bg-blue-600"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Signin;
